<template>
  <div>
    <v-toolbar
      :class="{ rtl: $i18n.locale === 'ar' }"
      v-if="showNavbar"
      app
      dark
      height="90"
    >
      <v-toolbar-side-icon @click.stop="clickDrawer"></v-toolbar-side-icon>
      <img src="../assets/images/ottu-logo.png" alt="Ottu" class="ottu-logo" />
      <v-spacer></v-spacer>

      <!--top-right menu-->
      <span>{{ 'Hello' }} {{ userDetails.username }}</span>
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-card
          v-if="userDetails.username === undefined"
          class="action-menu-loader"
        >
          <vue-skeleton-loader animation="fade" />
        </v-card>
        <v-list v-else>
          <v-list-tile
            v-if="userDetails.is_superuser && merchantDetails.admin_url"
            :href="merchantDetails.admin_url"
          >
            <v-list-tile-action>
              <v-icon>settings</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>{{
              $t('Administration panel')
            }}</v-list-tile-title>
          </v-list-tile>
          <!-- Commented out until https://pm.kuwaitnet.com/issues/85497 is merged to master -->
          <!-- <v-list-tile
            v-if="userDetails.is_superuser || isMerchantAdmin"
            :href="addUserURL"
          >
            <v-list-tile-action>
              <v-icon>person_add_alt</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>{{ $t('Add User') }}</v-list-tile-title>
          </v-list-tile> -->
          <v-list-tile @click="switchLanguage">
            <v-list-tile-action>
              <v-icon>language</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>{{ $t('Switch language') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile :to="{ path: localePath('/user') }">
            <v-list-tile-action>
              <v-icon>account_box</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>{{ $t('Account settings') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="logout">
            <v-list-tile-action>
              <v-icon>logout</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>{{ $t('Logout') }}</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
      app
      v-model="drawer"
      :right="$vuetify.rtl"
      class="side-menu"
    >
      <v-img
        v-if="merchantDetails.logo_url"
        :src="merchantDetails.logo_url"
        max-height="150px"
        contain
      >
      </v-img>
      <p class="mt-2 headline" v-if="merchantDetails.title_en">
        {{
          this.$i18n.locale === 'ar' && merchantDetails.title_ar
            ? merchantDetails.title_ar
            : merchantDetails.title_en
        }}
      </p>
      <p class="subheading" v-if="merchantDetails.subheader">
        {{ merchantDetails.subheader }}
      </p>
      <vue-skeleton-loader v-if="isLoadingSections" animation="fade" />
      <v-list v-else>
        <!-- Dashboard -->
        <v-list-tile
          :to="{ path: localePath('/dashboard') }"
          :exact="$route.path !== 'dashboard'"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <v-list-tile-action>
            <v-icon>dashboard</v-icon>
          </v-list-tile-action>
          <v-list-tile-title>
            {{ $t('Dashboard') }}
          </v-list-tile-title>
        </v-list-tile>
        <!-- Express search -->
        <v-list-tile
          v-if="
            isSectionAvailable('express_search') &&
            sections.express_search.index
          "
          :to="{ path: localePath('/search/express') }"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <v-list-tile-action>
            <v-icon>search</v-icon>
          </v-list-tile-action>
          <v-list-tile-title>
            {{ $t('Express Search') }}
          </v-list-tile-title>
        </v-list-tile>

        <!-- Payment request-->
        <v-list-group
          v-if="isSectionAvailable('payment_request')"
          prepend-icon="payment"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title>{{ $t('Payment Request') }}</v-list-tile-title>
            </v-list-tile>
          </template>
          <v-list-tile
            v-if="sections.payment_request.create"
            :to="{ path: localePath('/payment-request/create') }"
          >
            <v-list-tile-title>{{ $t('Create') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.payment_request.transactions"
            :to="{ path: localePath('/payment-request/transactions') }"
          >
            <v-list-tile-title>{{ $t('Transactions') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.payment_request.deleted_transactions"
            :to="{ path: localePath('/payment-request/deleted-transactions') }"
          >
            <v-list-tile-title>{{
              $t('Deleted Paid Transactions')
            }}</v-list-tile-title>
          </v-list-tile>
          <v-list-group
            sub-group
            v-if="
              sections.payment_request.proxy_fields_enabled &&
              hasHeadersPerm('paymentrequestpayment')
            "
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t('Settings') }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile
              :to="{ path: localePath('/payment-request/table-headers') }"
            >
              <v-list-tile-title>{{ $t('Table Headers') }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
        </v-list-group>
        <!-- Invoices-->
        <v-list-group
          v-if="isSectionAvailable('invoice')"
          prepend-icon="receipt"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title>{{ $t('Invoice') }}</v-list-tile-title>
            </v-list-tile>
          </template>
          <v-list-tile
            v-if="sections.invoice.create"
            :to="{ path: localePath('/payment-request/invoice') }"
          >
            <v-list-tile-title>{{ $t('Create Invoice') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.invoice.invoices"
            :to="{ path: localePath('/invoices') }"
            exact
          >
            <v-list-tile-title>{{ $t('Invoices') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.invoice.deleted_invoices"
            :to="{ path: localePath('/invoices/deleted-invoices') }"
          >
            <v-list-tile-title>{{
              $t('Deleted Paid Invoices')
            }}</v-list-tile-title>
          </v-list-tile>
        </v-list-group>
        <!-- Real Estate-->
        <v-list-group
          v-if="isSectionAvailable('real_estate')"
          :class="{ 'right-to-left': $vuetify.rtl }"
          prepend-icon="apartment"
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title>{{ $t('Real Estate') }}</v-list-tile-title>
            </v-list-tile>
          </template>
          <v-list-group sub-group>
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t('Lookup') }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile
              v-if="sections.real_estate.all"
              :to="{ path: localePath('/real_estate/unit_types') }"
            >
              <v-list-tile-title>{{ $t('Unit Types') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-if="sections.real_estate.all"
              :to="{ path: localePath('/real_estate/cash_treasuries') }"
            >
              <v-list-tile-title>{{
                $t('Cash Treasury Listing')
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-if="sections.real_estate.all"
              :to="{ path: localePath('/real_estate/banks') }"
            >
              <v-list-tile-title>{{ $t('Bank Listing') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-if="sections.real_estate.all"
              :to="{ path: localePath('/real_estate/profiles') }"
            >
              <v-list-tile-title>{{ $t('Profiles') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-if="sections.real_estate.all"
              :to="{
                path: localePath('/real_estate/property_classifications'),
              }"
            >
              <v-list-tile-title>{{
                $t('Property Classifications')
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-if="sections.real_estate.all"
              :to="{ path: localePath('/real_estate/property_issues') }"
            >
              <v-list-tile-title>{{
                $t('Maintenance Types')
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
          <v-list-group sub-group>
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t('Main listings') }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile
              v-if="sections.real_estate.all"
              :to="{ path: localePath('/real_estate/properties') }"
            >
              <v-list-tile-title>{{
                $t('Property management')
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-if="sections.real_estate.all"
              :to="{ path: localePath('/real_estate/tenant_management') }"
            >
              <v-list-tile-title>{{
                $t('Tenant Management')
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-if="sections.real_estate.all"
              :to="{ path: localePath('/real_estate/rent_management') }"
            >
              <v-list-tile-title>{{ $t('Rent Management') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :to="{
                path: localePath('/real_estate/manual_generating_payments'),
              }"
            >
              <v-list-tile-title>{{
                $t('Generate Invoices')
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-if="sections.real_estate.transactions"
              :to="{ path: localePath('/real-estate/transactions') }"
            >
              <v-list-tile-title>{{ $t('Transactions') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-if="sections.real_estate.deleted_transactions"
              :to="{ path: localePath('/real-estate/deleted-transactions') }"
            >
              <v-list-tile-title>{{
                $t('Deleted Paid Transactions')
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
          <v-list-group sub-group v-if="sections.real_estate.all">
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t('Maintenance') }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile
              :to="{ path: localePath('/real_estate/property_tickets_list') }"
            >
              <v-list-tile-title>{{
                $t('Maintenance Tickets')
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
          <v-list-group sub-group v-if="sections.real_estate.all">
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t('Settings') }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile
              :to="{ path: localePath('/real-estate/table-headers') }"
              v-if="
                sections.real_estate.proxy_fields_enabled &&
                hasHeadersPerm('real_estate')
              "
            >
              <v-list-tile-title>{{ $t('Table Headers') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile :to="{ path: localePath('/real_estate') }">
              <v-list-tile-title>{{ $t('Configuration') }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
          <v-list-group sub-group v-if="sections.real_estate.all">
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{
                  $t('Action listings')
                }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <!-- <v-list-tile
              :to="{ path: localePath('/real_estate/edit_price_list') }"
            >
              <v-list-tile-title>{{ $t("Edit Price List") }}</v-list-tile-title>
            </v-list-tile> -->
            <v-list-tile
              :to="{ path: localePath('/real_estate/new_contract_list') }"
            >
              <v-list-tile-title>{{
                $t('New Contract List')
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :to="{ path: localePath('/real_estate/renew_contract_list') }"
            >
              <v-list-tile-title>{{
                $t('Renew Contract List')
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :to="{ path: localePath('/real_estate/terminate_contract_list') }"
            >
              <v-list-tile-title>{{
                $t('Terminate Contract List')
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :to="{ path: localePath('/real_estate/advance_payment_list') }"
            >
              <v-list-tile-title>{{
                $t('Advance Payment List')
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :to="{ path: localePath('/real_estate/manual_payment_list') }"
            >
              <v-list-tile-title>{{
                $t('Manual Payment List')
              }}</v-list-tile-title>
            </v-list-tile>
            <!-- <v-list-tile
              :to="{
                path: localePath('/real_estate/suspended_resumed_contract')
              }"
            >
              <v-list-tile-title>{{
                $t("Suspended Contracts")
              }}</v-list-tile-title>
            </v-list-tile> -->

            <v-list-tile
              :to="{ path: localePath('/real_estate/modified_contract') }"
            >
              <v-list-tile-title>{{
                $t('Modified Contracts')
              }}</v-list-tile-title>
            </v-list-tile>
            <!-- <v-list-tile
              :to="{ path: localePath('/real_estate/invoice_list') }"
            >
              <v-list-tile-title>{{ $t("Invoice List") }}</v-list-tile-title>
            </v-list-tile> -->
          </v-list-group>
          <v-list-group sub-group v-if="sections.real_estate.all">
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t('Reports') }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile
              :to="{
                path: localePath('/real_estate/shortly_expired_contracts'),
              }"
            >
              <v-list-tile-title>{{
                $t('Shortly Expired Contracts')
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :to="{
                path: localePath('/real_estate/generated_reports'),
              }"
            >
              <v-list-tile-title>{{
                $t('Generated Reports')
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
        </v-list-group>

        <!-- Bulk -->
        <v-list-group
          v-if="isSectionAvailable('bulk')"
          prepend-icon="update"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title>{{ $t('Bulk') }}</v-list-tile-title>
            </v-list-tile>
          </template>
          <v-list-tile
            v-if="sections.bulk.upload"
            :to="{ path: localePath('/bulk/uploads') }"
          >
            <v-list-tile-title>{{ $t('Uploads') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.bulk.transactions"
            :to="{ path: localePath('/bulk/transactions') }"
          >
            <v-list-tile-title>{{ $t('Transactions') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.bulk.deleted_transactions"
            :to="{ path: localePath('/bulk/deleted-transactions') }"
          >
            <v-list-tile-title>{{
              $t('Deleted Paid Transactions')
            }}</v-list-tile-title>
          </v-list-tile>
          <v-list-group
            sub-group
            v-if="sections.bulk.proxy_fields_enabled && hasHeadersPerm('bulk')"
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t('Settings') }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile :to="{ path: localePath('/bulk/table-headers') }">
              <v-list-tile-title class="submenu">{{
                'Table Headers'
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
        </v-list-group>
        <!-- Customer Payment -->
        <v-list-group
          v-if="isSectionAvailable('customer_payment')"
          prepend-icon="account_balance_wallet"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title>{{
                $t('Customer payment')
              }}</v-list-tile-title>
            </v-list-tile>
          </template>
          <v-list-tile
            v-if="sections.customer_payment.transactions"
            :to="{ path: localePath('/customer-payment/transactions') }"
          >
            <v-list-tile-title>{{ $t('Transactions') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.customer_payment.deleted_transactions"
            :to="{ path: localePath('/customer-payment/deleted-transactions') }"
          >
            <v-list-tile-title>{{
              $t('Deleted Paid Transactions')
            }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.customer_payment.payment_restriction"
            :to="{ path: localePath('/customer-payment/payment-restriction') }"
          >
            <v-list-tile-title>{{
              $t('Payment Restriction')
            }}</v-list-tile-title>
          </v-list-tile>
          <v-list-group
            sub-group
            v-if="
              sections.customer_payment.proxy_fields_enabled &&
              hasHeadersPerm('customerpayment')
            "
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t('Settings') }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile
              :to="{ path: localePath('/customer-payment/table-headers') }"
            >
              <v-list-tile-title class="submenu">{{
                $t('Table Headers')
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
        </v-list-group>
        <!-- Catalogue -->
        <v-list-group
          v-if="isSectionAvailable('catalogue')"
          prepend-icon="store"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title>{{ $t('Catalogue') }}</v-list-tile-title>
            </v-list-tile>
          </template>
          <v-list-tile
            v-if="sections.catalogue.transactions"
            :to="{ path: localePath('/catalogue/transactions') }"
          >
            <v-list-tile-title>{{ $t('Transactions') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.catalogue.deleted_transactions"
            :to="{ path: localePath('/catalogue/deleted-transactions') }"
          >
            <v-list-tile-title>{{
              $t('Deleted Paid Transactions')
            }}</v-list-tile-title>
          </v-list-tile>
          <v-list-group
            sub-group
            v-if="
              sections.catalogue.proxy_fields_enabled &&
              hasHeadersPerm('catalogue')
            "
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t('Settings') }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile :to="{ path: localePath('/catalogue/table-headers') }">
              <v-list-tile-title class="submenu">{{
                $t('Table Headers')
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
        </v-list-group>
        <v-list-group
          v-if="isSectionAvailable('e_commerce')"
          prepend-icon="local_grocery_store"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title>{{ $t('E-commerce') }}</v-list-tile-title>
            </v-list-tile>
          </template>
          <v-list-tile
            v-if="sections.e_commerce.transactions"
            :to="{ path: localePath('/e-commerce/transactions') }"
          >
            <v-list-tile-title>{{ $t('Transactions') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            :to="{ path: localePath('/e-commerce/deleted-transactions') }"
            v-if="sections.e_commerce.deleted_transactions"
          >
            <v-list-tile-title>{{
              $t('Deleted Paid Transactions')
            }}</v-list-tile-title>
          </v-list-tile>
          <v-list-group
            sub-group
            v-if="
              sections.e_commerce.proxy_fields_enabled &&
              hasHeadersPerm('ecompayment')
            "
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t('Settings') }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile
              :to="{ path: localePath('/e-commerce/table-headers') }"
            >
              <v-list-tile-title class="submenu">{{
                $t('Table Headers')
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
        </v-list-group>

        <v-list-group
          v-if="isSectionAvailable('event')"
          prepend-icon="event"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title>{{ $t('Events') }}</v-list-tile-title>
            </v-list-tile>
          </template>

          <v-list-tile
            v-if="!useCloudEventService && sections.event.transactions"
            :to="{ path: localePath('/event/transactions') }"
          >
            <v-list-tile-title>{{ $t('Transactions') }}</v-list-tile-title>
          </v-list-tile>

          <v-list-tile
            v-if="!useCloudEventService && sections.event.deleted_transactions"
            :to="{ path: localePath('/event/deleted-transactions') }"
          >
            <v-list-tile-title>{{
              $t('Deleted Paid Transactions')
            }}</v-list-tile-title>
          </v-list-tile>

          <v-list-tile
            v-if="sections.event.bookings"
            v-bind="getEventNavProps('/event/bookings')"
            @click="navigateToNewRoute($event, localePath('/event/bookings'))"
          >
            <v-list-tile-title>{{ $t('Bookings') }}</v-list-tile-title>
          </v-list-tile>

          <v-list-tile
            v-if="sections.event.create"
            v-bind="getEventNavProps('/event/create')"
            @click="navigateToNewRoute($event, localePath('/event/create'))"
          >
            <v-list-tile-title>{{ $t('Create') }}</v-list-tile-title>
          </v-list-tile>

          <v-list-tile
            v-if="sections.event.fulfillment"
            v-bind="getEventNavProps('/event/fulfillment')"
            @click="
              navigateToNewRoute($event, localePath('/event/fulfillment'))
            "
          >
            <v-list-tile-title>{{ $t('Fulfillment') }}</v-list-tile-title>
          </v-list-tile>

          <v-list-tile
            v-if="useCloudEventService"
            v-bind="getEventNavProps('/event/event-list')"
            @click="navigateToNewRoute($event, localePath('/event/event-list'))"
          >
            <v-list-tile-title>
              {{ $t('Events') }}
            </v-list-tile-title>
          </v-list-tile>

          <v-list-tile
            v-if="useCloudEventService"
            v-bind="getEventNavProps('/event/event-category-list')"
            @click="
              navigateToNewRoute(
                $event,
                localePath('/event/event-category-list'),
              )
            "
          >
            <v-list-tile-title>
              {{ $t('Event Categories') }}
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="useCloudEventService"
            v-bind="getEventNavProps('/event/event-subticket-list')"
            @click="
              navigateToNewRoute(
                $event,
                localePath('/event/event-subticket-list'),
              )
            "
          >
            <v-list-tile-title>
              {{ $t('Event Subtickets') }}
            </v-list-tile-title>
          </v-list-tile>

          <v-list-tile
            v-if="useCloudEventService"
            v-bind="getEventNavProps('/event/event-time-list')"
            @click="
              navigateToNewRoute($event, localePath('/event/event-time-list'))
            "
          >
            <v-list-tile-title>
              {{ $t('Event Times') }}
            </v-list-tile-title>
          </v-list-tile>

          <v-list-group
            sub-group
            v-if="
              (!useCloudEventService &&
                sections.event.proxy_fields_enabled &&
                hasHeadersPerm('eventpayment')) ||
              useCloudEventService
            "
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t('Settings') }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile
              v-if="!useCloudEventService"
              :to="{ path: localePath('/event/table-headers') }"
            >
              <v-list-tile-title class="submenu">{{
                $t('Table Headers')
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-else
              v-bind="getEventNavProps('/event/plugin-config')"
              @click="
                navigateToNewRoute($event, localePath('/event/plugin-config'))
              "
            >
              <v-list-tile-title class="submenu">{{
                $t('Configuration')
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
        </v-list-group>

        <v-list-group
          v-if="isSectionAvailable('food_ordering')"
          prepend-icon="fastfood"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title>{{ $t('Food ordering') }}</v-list-tile-title>
            </v-list-tile>
          </template>
          <v-list-tile
            v-if="sections.food_ordering.transactions"
            :to="{ path: localePath('/food_ordering/transactions') }"
          >
            <v-list-tile-title>{{ $t('Transactions') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.food_ordering.deleted_transactions"
            :to="{ path: localePath('/food_ordering/deleted-transactions') }"
          >
            <v-list-tile-title>{{
              $t('Deleted Paid Transactions')
            }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.food_ordering.orders"
            :to="{ path: localePath('/food-ordering/orders') }"
          >
            <v-list-tile-title>
              <span>{{ $t('Orders') }}</span>
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.food_ordering.product_bulk_create"
            :to="{ path: localePath('/food-ordering/bulk-create') }"
          >
            <v-list-tile-title>{{
              $t('Product bulk create')
            }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.food_ordering.branches"
            :to="{ path: localePath('/food-ordering/branches') }"
          >
            <v-list-tile-title>{{ $t('Branch') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.food_ordering.fulfillment"
            :to="{ path: localePath('/food-ordering/fulfillment') }"
          >
            <v-list-tile-title>{{ $t('Fulfillment') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-group
            sub-group
            v-if="
              sections.food_ordering.proxy_fields_enabled &&
              hasHeadersPerm('foodorderingpayment')
            "
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t('Settings') }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile
              :to="{ path: localePath('/food_ordering/table-headers') }"
            >
              <v-list-tile-title class="submenu">{{
                $t('Table Headers')
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
        </v-list-group>

        <!-- permisions request-->
        <v-list-group
          prepend-icon="store"
          v-if="isSectionAvailable('ticket')"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title
                >{{ $t('Tickets') }}
                <span style="margin-inline-start: 10px">
                  <RequestNotificationBadge :getters="enabledNotification()" />
                </span>
              </v-list-tile-title>
            </v-list-tile>
          </template>
          <v-list-tile
            v-if="sections.ticket.user_permission_changes"
            :to="{ path: localePath('/ticket/approve-permissions') }"
          >
            <v-list-tile-title>
              {{ $t('Permissions Requests') }}
              <span style="margin-inline-start: 10px">
                <RequestNotificationBadge :getters="permissionRequestGetters" />
              </span>
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.ticket.operations_approval"
            :to="{ path: localePath('/ticket/approve-refund-void') }"
          >
            <v-list-tile-title>
              {{ $t('Operations Requests') }}
              <span style="margin-inline-start: 10px">
                <RequestNotificationBadge :getters="operationRequestGetters" />
              </span>
            </v-list-tile-title>
          </v-list-tile>
        </v-list-group>

        <v-list-tile
          :to="{ path: localePath('/generated-reports') }"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <v-list-tile-action>
            <v-icon>summarize</v-icon>
          </v-list-tile-action>
          <v-list-tile-title>
            {{ $t('Generated Reports') }}
          </v-list-tile-title>
        </v-list-tile>

        <v-list-tile
          :class="{ 'right-to-left': $vuetify.rtl }"
          :to="{ path: localePath('/help') }"
        >
          <v-list-tile-action>
            <v-icon>contact_support</v-icon>
          </v-list-tile-action>
          <v-list-tile-title>
            {{ $t('Help/Support') }}
          </v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <v-footer app></v-footer>
    <RenewalAlert />
  </div>
</template>

<script>
import json5 from 'json5';
import { navigateToUrl } from 'single-spa';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import RequestNotificationBadge from '../components/RequestNotificationBadge';
import { PROXY_FIELDS_FLAG } from '../constants';
import { changeFavicon } from '../lib/dom_direct';
import { getAdminPanelAddUserURL, getKeycloakAddUserURL } from '../lib/url';
import LanguageSwitchMixin from '../mixins/LanguageSwitchMixin';
import PermissionsRequestTicketService from '../services/tickets/permissionsRequest';
import RefundVoidRequestTicketService from '../services/tickets/refundVoidRequest';

import RenewalAlert from './RenewalAlert.vue';

export default {
  name: 'Dashboard',
  components: { RequestNotificationBadge, RenewalAlert },
  mixins: [LanguageSwitchMixin],
  data: () => ({
    drawer: true,
    showNavbar: true,
    sections: {},
    merchantDetails: {},
    isLoadingSections: true,
    //keys
    permissionRequestGetters: [
      { namespace: 'ticket', map: 'getUnprocessedCount' },
    ],
    //keys
    operationRequestGetters: [{ namespace: 'ticket', map: 'getNewOperation' }],
    allowedSections: [],
  }),
  async created() {
    if (this.$route.meta.hide_navbar) {
      this.showNavbar = false;
      this.drawer = false;
    }
  },
  computed: {
    userDetails() {
      return Vue.prototype.$user || {};
    },
    isMerchantAdmin() {
      return Vue.prototype.$isMerchantAdmin;
    },
    addUserURL() {
      let url = '';
      if (Vue.prototype.$isKeycloakAuth) {
        url = getKeycloakAddUserURL(
          Vue.prototype.$keycloakDomain,
          Vue.prototype.$merchant,
        );
      } else {
        url = getAdminPanelAddUserURL(this.merchantDetails.admin_url);
      }
      return url;
    },
    useCloudEventService() {
      return Vue.prototype.$useCloudEventService;
    },
    ...mapGetters('dashboard', ['getSections']),
  },
  methods: {
    enabledNotification() {
      return [
        ...this.permissionRequestGetters,
        ...this.operationRequestGetters,
      ];
    },
    clickDrawer() {
      this.drawer = !this.drawer;
      const element = document.getElementsByClassName('application')[0];
      if (!this.drawer && element) {
        element.classList.remove('navbarShow');
      } else if (element) {
        element.classList.add('navbarShow');
      } else {
        const vElement = document.getElementsByClassName('v-application')[0];
        if (!this.drawer && vElement) {
          vElement.classList.remove('navbarShow');
        } else if (vElement) {
          vElement.classList.add('navbarShow');
        }
      }
    },
    hasHeadersPerm(name) {
      return (
        Vue.prototype.$user.is_superuser &&
        (Vue.prototype.$user.is_superuser ||
          (Object.keys(Vue.prototype.$user.user_permissions).length > 0 &&
            Vue.prototype.$user.user_permissions[
              `change_${name}transactionproxyfield`
            ] !== undefined))
      );
    },
    setMerchantConfigs(data) {
      const {
        admin_url,
        logo_url,
        favicon_url,
        title,
        title_en,
        title_ar,
        subheader,
      } = data;
      this.merchantDetails = {
        admin_url,
        logo_url,
        favicon_url,
        title,
        title_en,
        title_ar,
        subheader,
      };

      if (favicon_url) {
        changeFavicon(favicon_url);
      }
      if (title) {
        document.title = title;
      }
    },
    logout() {
      Vue.prototype.$sessionStateWorkerInstance.logoutAcrossTabs();
    },
    isSectionAvailable(sectionName) {
      const sectionConfig = this.sections[sectionName];
      const isSectionAvailableForServer =
        sectionName === 'ticket' ||
        sectionName === 'express_search' ||
        this.allowedSections?.some(k => sectionName === k);
      const isSectionAvailableForUser =
        sectionConfig &&
        Object.keys(sectionConfig).some(
          (k) => sectionConfig[k] === true && k !== PROXY_FIELDS_FLAG,
        );

      return isSectionAvailableForServer && isSectionAvailableForUser;
    },
    update_ticket_count() {
      if (this.sections.ticket.user_permission_changes) {
        PermissionsRequestTicketService.getList({
          status: 'NEW',
          limit: 100,
        }).then(res => {
          const count = res.data.results.length;
          this.setUnprocessed(count);
        });
      }
      if (this.sections.ticket.operations_approval) {
        RefundVoidRequestTicketService.getCount().then(res => {
          const count = res.data.count;
          this.setNewOperation(count);
        });
      }
    },
    navigateToNewRoute($event, path) {
      if (this.useCloudEventService) {
        if ($event.ctrlKey) {
          window.open(path);
        } else {
          navigateToUrl(path);
        }
      }
    },
    getEventNavProps(path) {
      if (this.useCloudEventService) {
        return {
          class: {
            'primary--text v-list__tile--active':
              this.$route.path === this.localePath(path),
          },
        };
      }

      return {
        to: {
          path: this.localePath(path),
        },
      };
    },
    ...mapActions('ticket', ['setUnprocessed', 'setNewOperation']),
  },
  watch: {
    sections: function () {
      if (this.isSectionAvailable('ticket')) {
        /// if ticket and permission changes is enabled => get all ticket then count the new and show it
        this.update_ticket_count();
      }
    },
    getSections: {
      immediate: true,
      handler(val) {
        if (val) {
          this.allowedSections = [...val.section_plugins];
          delete val.section_plugins;
          this.sections = val;
          this.isLoadingSections = false;
        }
        this.setMerchantConfigs(Vue.prototype.$config);
      },
    },
    $n_ws_notify: function () {
      const ws_data = json5.parse(Vue.prototype.$n_ws_notify);
      if (
        (this.isSectionAvailable('ticket') &&
          ws_data.target?.plugin &&
          ws_data.target?.plugin === 'permissions_approval') ||
        ws_data.target?.plugin === 'operations_approval'
      ) {
        /// if ticket and permission changes is enabled => get all ticket then count the new and show it
        if (ws_data.content.data && ws_data.content.data.badge_update) {
          this.update_ticket_count();
        }
      }
    },
  },
};
</script>

<style>
.application--wrap {
  min-height: auto !important;
}
.ottu-logo {
  margin-left: 10px;
  margin-right: 10px;
  height: 40px;
}
.rounded-card {
  border-radius: 0.625rem !important;
}
.v-navigation-drawer > .v-list .v-list__tile--active .v-list__tile__title {
  color: #f77c1a !important;
}
.v-list__tile--active {
  background: transparent !important;
  font-weight: 700 !important;
  transition: 0.3s;
}
.theme--light.v-list .v-list__tile--link:hover,
.theme--light.v-list .v-list__tile--highlighted,
.theme--light.v-list .v-list__group__header:hover:hover {
  background: transparent !important;
}
.v-list__tile__title {
  transition: 0.3s;
  text-overflow: initial;
}
.v-list__tile__title:hover {
  /* color: #f77c1a;  */
  color: var(--primary);
}
.v-list__group__items {
  padding-left: 4rem !important;
}
.submenu {
  margin-left: -2rem;
}
.application--is-rtl .v-list__group__items {
  padding-right: 4rem !important;
  padding-left: unset !important;
}
.application--is-rtl .v-list__tile__title.submenu {
  margin-right: -2rem !important;
}
.right-to-left > .v-list__group__items {
  padding-left: 0 !important;
}
.right-to-left > .v-list__group__items .v-list__tile__title {
  padding-right: 6rem;
}
.right-to-left .v-list__group__header .v-list__tile {
  padding: 0 !important;
}

.v-list__tile_real .v-list__tile {
  padding: 0px !important;
}
.right-to-left .v-list__tile__title {
  text-align: right;
  padding-right: 0rem;
}
.right-to-left .v-list__group > .v-list__group__header .v-list__tile__title {
  text-align: right;
  padding-right: 4rem;
}
.right-to-left .v-list__group > .v-list__group__items .v-list__tile__title {
  text-align: right;
  padding-right: 6rem;
}
.right-to-left .v-list__group > .v-list__group__items {
  padding-left: 0rem !important;
}
</style>

<style scoped>
.loader {
  width: 100% !important;
  height: 80% !important;
  margin-top: 24px;
}
.action-menu-loader .loader {
  width: 240px !important;
  height: 240px !important;
  margin: 0px;
}
.subheading {
  text-align: center;
}
.rtl {
  direction: rtl;
}
</style>

<style scoped>
.headline {
  text-align: center;
}
</style>
