import { navigateToUrl } from 'single-spa';
import Vue from 'vue';

export default {
  methods: {
    switchLanguage() {
      const lang = this.$i18n.locale === 'ar' ? 'ar' : 'en';
      const target_lang = this.$i18n.locale === 'en' ? 'ar' : 'en';
      this.$i18n.locale = target_lang;
      const path = this.$route.fullPath.replaceAll(
        `/${lang}/`,
        `/${target_lang}/`,
      );

      // check if route is part of Event MF.
      // Workaround for vue-router3 and vue-router4 compatibility issues
      if (
        Vue.prototype.$useCloudEventService &&
        path.includes('event/') &&
        !(path.includes('transaction') || path.includes('table-header'))
      ) {
        navigateToUrl(path);
      } else {
        this.$router.push({ path: path });
      }
    },
    localePath(path) {
      return `/${this.$i18n.locale}${path}`;
    },
  },
};
